import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/components_Button/Button'; // Import the Button component

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap to a new line */
  justify-content: space-between;
  gap: 5%; /* Use gap for consistent spacing */
`;

const SkillSelector = () => {
  const [selectedSkills, setSelectedSkills] = useState([]);

  const skills = [
    "手足をバタバタ",
    "首が座る",
    "触れたものをつかむ",
    "寝返り",
    "お座り",
    "はいはい",
    "ささえ立ち",
    "つかまり立ち",
    "伝え歩き",
    "タッチ",
    "一人で歩く",
    "走る",
    "一人遊び"
  ];

  const toggleSkill = (skill) => {
    setSelectedSkills(prevSelected =>
      prevSelected.includes(skill)
        ? prevSelected.filter(s => s !== skill)
        : [...prevSelected, skill]
    );
  };

  return (
    <ButtonContainer>
      {skills.map(skill => (
        <Button
          key={skill}
          label={skill}
          selected={selectedSkills.includes(skill)}
          onClick={() => toggleSkill(skill)}
          width="30%" /* Adjust width to fit within container */
        />
      ))}
    </ButtonContainer>
  );
};

export default SkillSelector;