import React from 'react';
import styled from 'styled-components';

const CommentContainer = styled.div`
  margin-top: 20px;
  padding: 10px 0px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const CommentText = styled.p`
  font-size: 18px;
`;

const CommentComponent = ({ income, age }) => {
  const getComment = (income, age) => {
    if (income >= 1000 && age < 30) {
      return (
        <>
          おめでとうございます！<br />あなたの努力と才能が実を結び、<br />
          素晴らしい成果を達成しています。<br />この成功をさらに活かし、<br /><br />
          次なるステップへと進むための<br />サポートを提供します。<br /><br />
          これからもあなたの成長を<br />応援しています！
        </>
      );
    } else if (income < 500) {
      return (
        <>
          素晴らしいですね！<br />あなたは確実に目標に向かって<br />進んでいます。<br /><br />
          この調子でさらなる高みを<br />指しましょう。<br /><br />
          私たちは<br />あなたが次のステージに進むための<br />最適なアドバイスと<br />
          サポートを提供します！
        </>
      );
    }
    return (
      <>
        あなたの現在の状況を<br />しっかりと把握し<br />
        次のステップへ進むための<br />体的なプランを一緒に考えましょう<br /><br />
        どんな小さな一歩も大切です<br />
        私たちは常にあなたの味方です！
      </>
    );
  };

  return (
    <CommentContainer>
      <CommentText>{getComment(income, age)}</CommentText>
    </CommentContainer>
  );
};

export default CommentComponent;