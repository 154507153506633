import React from 'react';
import styled from 'styled-components';

const AdContainer = styled.div`
  margin-top: 20px;
  padding: 150px 0px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const AdText = styled.p`
  font-size: 16px;
`;

const AdComponent = ({ income, age }) => {
  const getAdContent = (income, age) => {
    if (income >= 1000 && age < 30) {
      return (
        <>
          高級サウナや快眠アイテムをチェックして、
          <br />
          パフォーマンスを向上させましょう！
        </>
      );
    } else if (income < 500) {
      return (
        <>
          転職サイトを利用して、
          <br />
          次のステップに進みましょう！
        </>
      );
    }
    return "あなたにぴったりのおすすめ商品を見つけましょう！";
  };

  return (
    <AdContainer>
      <AdText>{getAdContent(income, age)}</AdText>
    </AdContainer>
  );
};

export default AdComponent;