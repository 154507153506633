import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HomeButton from '../components/components_Button/HomeButton';
import IncomeSimulator from '../components_page/Income/IncomeBase';

const BackgroundContainer = styled.div`
  background-color: #2c6090; /* Set your desired background color */
  min-height: 100vh; /* Ensure it covers the full viewport height */
  font-family: 'Noto Sans', sans-serif; /* Changed to Noto Sans */

`;

const IncomeSim = () => {
  return (
    <BackgroundContainer>
      <Layout headerTitle="年収偏差値">
        <IncomeSimulator />

      </Layout>
    </BackgroundContainer>
  );
};

export default IncomeSim;