import React from 'react';
import styled from 'styled-components';
import Button from '../../components/components_Button/Button'; // Import the Button component

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between; 
`;

const Spacer = styled.div`
  width: 5%;
`;

const GenderSelector = ({ selectedGender, onSelect }) => {
  return (
    <ButtonContainer>
      <Button
        label="男性"
        selected={selectedGender === "男性"}
        onClick={() => onSelect("男性")}
        width="30%"
      />
      <Spacer />
      <Button
        label="女性"
        selected={selectedGender === "女性"}
        onClick={() => onSelect("女性")}
        width="30%"
      />
      <Spacer />
      <Button
        label="その他"
        selected={selectedGender === "その他"}
        onClick={() => onSelect("その他")}
        width="30%"
      />
    </ButtonContainer>
  );
};

export default GenderSelector;