import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Container = styled.div`
  width: 100%;
  height: 75px;
  background: transparent; /* Set to transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 1000;
`;

const TitleContainer = styled.div`
  width: 240px;
  height: ${props => props.showSubtitle ? '40px' : '30px'};
  background: transparent; /* Set to transparent */
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const Header = ({ title, showSubtitle = true }) => (
  <Container>
    <TitleContainer showSubtitle={showSubtitle}>
      <StyledLink to="/">
        <Logo 
          title={title} 
          subtitle={showSubtitle ? "Created By ONEPAGE" : null} 
        />
      </StyledLink>
    </TitleContainer>
  </Container>
);

export default Header;