import React, { useState } from 'react';
import styled from 'styled-components';

const colors = {
  primary: '#007bff',
  background: '#f8f9fa',
  text: '#333333',
  selected: '#e0f7fa',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputDisplay = styled.div`
  font-size: 16px;
  padding: 15px 0px;
  border-radius: .8rem;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
  cursor:pointer;
  width: ${props => props.width || '30%'}; /* Use width prop */
`;

const Unit = styled.span`
  font-size: 16px;
  margin-left: 10px;
`;

const FullScreenOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Dropdown = styled.ul`
background-color:white; 
border-radius:.8rem; 
box-shadow:0px 4px 8px rgba(0,0,0,0.2); 
list-style:none; 
padding:.1rem; 
max-height:80vh; 
overflow-y:auto; 
width:85%; /* Set width to match input */
`;

const DropdownItem = styled.li`
font-size:18px; 
padding:.6rem; 
cursor:pointer;
text-align:left; 
border-bottom:none;

background-color:${props => (props.selected ? colors.selected : 'white')}; 

&:hover {
background-color:${colors.primary}; 
color:white; 
border-radius:.25rem; 
transition-duration:.3s; 
transition-timing-function:ease-in-out; 
transition-delay:.1s; 
transition-property:none; 
}
`;

const CustomDropdownInput = ({ value, setValue, options, unit, width }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleOptionClick = (option) => {
        setValue(option);
        setIsDropdownOpen(false);
    };

    return (
        <Container>
            <InputWrapper>
                <InputDisplay onClick={() => setIsDropdownOpen(true)} width={width}>
                    {value || "選択"}
                </InputDisplay>
                {unit && <Unit>{unit}</Unit>}
            </InputWrapper>
            {isDropdownOpen && (
                <FullScreenOverlay onClick={() => setIsDropdownOpen(false)}>
                    <Dropdown onClick={(e) => e.stopPropagation()} width={width}>
                        {options.map((option, index) => (
                            <DropdownItem
                                key={index}
                                onClick={() => handleOptionClick(option)}
                                selected={option === value}
                            >
                                {option}
                            </DropdownItem>
                        ))}
                    </Dropdown>
                </FullScreenOverlay>
            )}
        </Container>
    );
};

export default CustomDropdownInput;