import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  padding: 0.8rem 0.75rem;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fff;
  background-color: ${props => (props.disabled ? '#6b8eb3' : '#2c6090')};
  border: none;
  border-radius: 1rem;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  box-shadow: ${props => (props.disabled ? 'none' : '0px 4px 8px rgba(0,0,0,0.2)')};
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.1s ease;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  &:hover {
    background-color: ${props => (props.disabled ? '#666666' : '#2c6090')};
    box-shadow: ${props => (props.disabled ? 'none' : '0px 4px 8px rgba(0,0,0,0.3)')};
  }

  &:active {
    transform: scale(0.98); /* Slightly reduce size on click */
    background-color: ${props => (props.disabled ? '#6b8eb3' : '#2c6090')}; /* Darker shade on click */
    box-shadow: ${props => (props.disabled ? 'none' : '0px 2px 4px rgba(0,0,0,0.2)')};
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const CheckButton = ({ onClick, disabled, children }) => (
  <StyledButton onClick={onClick} disabled={disabled}>
    {children}
  </StyledButton>
);

export default CheckButton;