import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { RiTwitterXLine } from "react-icons/ri";

const FooterContainer = styled.footer`
  background-color: #222222;
  padding: 20px 0;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
`;

const FollowUs = styled.div`
  font-size: 18px;
  color: #F5F5F5;
  margin-bottom: 10px;
  font-weight: bold;
`;

const SocialIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

const SocialIcon = styled.a`
  color: #ffffff;
  font-size: 24px;
  transition: color 0.3s;

  &:hover {
    color: #0d8ddb;
  }
`;

const SocialLabel = styled.div`
  font-size: 14px;
  color: #F5F5F5;
  margin-top: 5px;
  font-weight: normal;
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin: 15px 0;
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  color: #F5F5F5;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #007bff;
  }
`;

const Copyright = styled.div`
  font-size: 14px;
  color: #666;
  margin-top: 10px;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FollowUs>Follow Us</FollowUs>
      <SocialIconContainer>
        <SocialIcon href="https://x.com/ONEPAGE_no1" target="_blank" rel="noopener noreferrer">
          <RiTwitterXLine />
        </SocialIcon>
        <SocialLabel>X</SocialLabel>
      </SocialIconContainer>
      <LinkContainer>
        <StyledLink to="/profile">Profile</StyledLink>
        <StyledLink to="/disclaimer">Terms of Service</StyledLink>
        {/* Add more links here in the future */}
      </LinkContainer>
      <Copyright>
        &copy; 2023 Takumi. All rights reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default Footer;