import React from 'react';
import Header from './components_Header/Header';
import Footer from './Footer';

const Layout = ({ children, headerTitle, showSubtitle = true }) => (
  <div className="page-container">
    <Header title={headerTitle} showSubtitle={showSubtitle} />
    <main>{children}</main>

  </div>
);

export default Layout;