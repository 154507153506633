import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HomeButton from '../components/components_Button/HomeButton';
import { RiTwitterXLine } from "react-icons/ri";
import ProfileImg from '../images/ProfileImg.jpg';

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
  background-color: #f0f4f8;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  color: #2c3e50;
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const ImageFrame = styled.div`
  width: 150px;
  height: 150px;
  background-color: #e0e0e0;
  border-radius: 50%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* 画像を丸く表示するために追加 */
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Name = styled.h1`
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 10px;
`;

const Bio = styled.p`
  font-size: 1rem;
  color: #34495e;
  text-align: center;
  max-width: 400px;
  line-height: 1.5;
`;

const SocialLinks = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 15px;
`;

const SocialLink = styled.a`
  color: #111111;
  font-size: 1.5rem;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: #666666;
  }
`;

const ProfilePage = () => (
  <Layout headerTitle="ONE PAGE" showSubtitle={false}>
    <ProfileContainer>
      <Title>Profile</Title>
      <ImageFrame>
        <ProfileImage src={ProfileImg} alt="Takumi" />
      </ImageFrame>
      <Name>Takumi</Name>
      <Bio>
        {[
          "本業は左脳を多く使っている",
          "右脳が優位なUIUX勉強中",
          "なんでも自分でやってみないと気が済まない",
          "AIと共に孤軍奮闘中"
        ].map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </Bio>
      <SocialLinks>
        <SocialLink href="https://x.com/ONEPAGE_no1" target="_blank" rel="noopener noreferrer">
          <RiTwitterXLine />
        </SocialLink>
      </SocialLinks>
    </ProfileContainer>
    <HomeButton onClick={() => window.location.href = '/'} />
  </Layout>
);

export default ProfilePage;