import React from 'react';
import styled from 'styled-components';

const StyledLabel = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-align: left;
  margin: 15px 0px 10px;
`;

const LabelComponent = ({ text }) => {
  return <StyledLabel>{text}</StyledLabel>;
};

export default LabelComponent;