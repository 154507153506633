import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 10px 0px;
`;

const Button = styled.button`
  padding: 5px 10px;
  font-size: 16px;
  color: #666666; /* Use a lighter color for text */
  background-color: #e0e0e0; /* Use a muted background color */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #cccccc; /* Slightly darker on hover */
  }

  &:focus {
    outline: none;
    box-shadow: none; /* Remove focus shadow */
  }
`;

const HomeButton = ({ onClick }) => {
  return (
    <ButtonContainer>
      <Button onClick={onClick}>ホームに戻る</Button>
    </ButtonContainer>
  );
};

export default HomeButton;