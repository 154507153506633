import React from 'react';
import styled from 'styled-components';

const colors = {
  primary: '#2c6090',
  text: '#333333',
  selected: '#e0f7fa',
};

const StyledButton = styled.button`
  width: ${props => props.width || '30%'};
  font-size: ${props => props.fontSize || '16px'};
  height: ${props => props.height || '54px'};
  border-radius: 1rem;
  border: none;
  background-color: ${props => (props.selected ? colors.primary : 'white')};
  color: ${props => (props.selected ? 'white' : colors.text)};
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: ${props => (props.selected ? '0px 4px 8px rgba(0,0,0,0.2)' : '0px 2px 4px rgba(0,0,0,0.1)')};
  -webkit-tap-highlight-color:rgba(0,0,0,0);

  &:hover {
    background-color: ${colors.primary};
    color: white;
    transform: translateY(-2px);
    box-shadow: 0px 4px 8px rgba(0,0,0,0.2);
  }
`;

const Button = ({ label, selected, onClick, width, height }) => (
  <StyledButton
    selected={selected}
    onClick={onClick}
    width={width}
    height={height}
  >
    {label}
  </StyledButton>
);

export default Button;