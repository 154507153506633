const incomeData = {
  "鉱業": {
    "男性": {
      "19": {
        "mean": 199.8,
        "sd": 85.0
      },
      "20": {
        "mean": 243.5,
        "sd": 41.3
      },
      "25": {
        "mean": 283.6,
        "sd": 1.2
      },
      "30": {
        "mean": 334.0,
        "sd": 49.2
      },
      "35": {
        "mean": 380.0,
        "sd": 95.2
      },
      "40": {
        "mean": 422.6,
        "sd": 137.8
      },
      "45": {
        "mean": 409.7,
        "sd": 124.9
      },
      "50": {
        "mean": 440.6,
        "sd": 155.8
      },
      "55": {
        "mean": 438.9,
        "sd": 154.1
      },
      "60": {
        "mean": 360.1,
        "sd": 75.3
      },
      "65": {
        "mean": 281.3,
        "sd": 3.5
      }
    },
    "女性": {
      "19": {
        "mean": 226.7,
        "sd": 58.1
      },
      "20": {
        "mean": 245.0,
        "sd": 39.8
      },
      "25": {
        "mean": 296.0,
        "sd": 11.2
      },
      "30": {
        "mean": 328.1,
        "sd": 43.3
      },
      "35": {
        "mean": 334.3,
        "sd": 49.5
      },
      "40": {
        "mean": 371.2,
        "sd": 86.4
      },
      "45": {
        "mean": 292.0,
        "sd": 7.2
      },
      "50": {
        "mean": 300.7,
        "sd": 15.9
      },
      "55": {
        "mean": 346.6,
        "sd": 61.8
      },
      "60": {
        "mean": 246.0,
        "sd": 38.8
      },
      "65": {
        "mean": 210.9,
        "sd": 73.9
      }
    },
    "その他": {
      "19": {
        "mean": 203.6,
        "sd": 81.2
      },
      "20": {
        "mean": 243.9,
        "sd": 40.9
      },
      "25": {
        "mean": 286.5,
        "sd": 1.7
      },
      "30": {
        "mean": 333.2,
        "sd": 48.4
      },
      "35": {
        "mean": 373.7,
        "sd": 88.9
      },
      "40": {
        "mean": 413.1,
        "sd": 128.3
      },
      "45": {
        "mean": 386.8,
        "sd": 102.0
      },
      "50": {
        "mean": 420.9,
        "sd": 136.1
      },
      "55": {
        "mean": 425.8,
        "sd": 141.0
      },
      "60": {
        "mean": 353.9,
        "sd": 69.1
      },
      "65": {
        "mean": 274.2,
        "sd": 10.6
      }
    }
  },
  "建設": {
    "男性": {
      "19": {
        "mean": 200.2,
        "sd": 84.6
      },
      "20": {
        "mean": 234.6,
        "sd": 50.2
      },
      "25": {
        "mean": 273.2,
        "sd": 11.6
      },
      "30": {
        "mean": 314.6,
        "sd": 29.8
      },
      "35": {
        "mean": 345.6,
        "sd": 60.8
      },
      "40": {
        "mean": 375.0,
        "sd": 90.2
      },
      "45": {
        "mean": 409.6,
        "sd": 124.8
      },
      "50": {
        "mean": 446.5,
        "sd": 161.7
      },
      "55": {
        "mean": 453.5,
        "sd": 168.7
      },
      "60": {
        "mean": 371.1,
        "sd": 86.3
      },
      "65": {
        "mean": 322.4,
        "sd": 37.6
      }
    },
    "女性": {
      "19": {
        "mean": 188.1,
        "sd": 96.7
      },
      "20": {
        "mean": 228.7,
        "sd": 56.1
      },
      "25": {
        "mean": 254.9,
        "sd": 29.9
      },
      "30": {
        "mean": 264.5,
        "sd": 20.3
      },
      "35": {
        "mean": 266.1,
        "sd": 18.7
      },
      "40": {
        "mean": 260.3,
        "sd": 24.5
      },
      "45": {
        "mean": 277.3,
        "sd": 7.5
      },
      "50": {
        "mean": 296.1,
        "sd": 11.3
      },
      "55": {
        "mean": 296.2,
        "sd": 11.4
      },
      "60": {
        "mean": 256.2,
        "sd": 28.6
      },
      "65": {
        "mean": 212.3,
        "sd": 72.5
      }
    },
    "その他": {
      "19": {
        "mean": 199.0,
        "sd": 85.8
      },
      "20": {
        "mean": 233.5,
        "sd": 51.3
      },
      "25": {
        "mean": 269.5,
        "sd": 15.3
      },
      "30": {
        "mean": 306.4,
        "sd": 21.6
      },
      "35": {
        "mean": 333.9,
        "sd": 49.1
      },
      "40": {
        "mean": 356.7,
        "sd": 71.9
      },
      "45": {
        "mean": 387.2,
        "sd": 102.4
      },
      "50": {
        "mean": 422.1,
        "sd": 137.3
      },
      "55": {
        "mean": 432.5,
        "sd": 147.7
      },
      "60": {
        "mean": 360.0,
        "sd": 75.2
      },
      "65": {
        "mean": 315.2,
        "sd": 30.4
      }
    }
  },
  "製造": {
    "男性": {
      "19": {
        "mean": 189.6,
        "sd": 95.2
      },
      "20": {
        "mean": 214.4,
        "sd": 70.4
      },
      "25": {
        "mean": 247.2,
        "sd": 37.6
      },
      "30": {
        "mean": 281.7,
        "sd": 3.1
      },
      "35": {
        "mean": 321.8,
        "sd": 37.0
      },
      "40": {
        "mean": 351.0,
        "sd": 66.2
      },
      "45": {
        "mean": 377.9,
        "sd": 93.1
      },
      "50": {
        "mean": 406.5,
        "sd": 121.7
      },
      "55": {
        "mean": 426.5,
        "sd": 141.7
      },
      "60": {
        "mean": 310.9,
        "sd": 26.1
      },
      "65": {
        "mean": 258.0,
        "sd": 26.8
      }
    },
    "女性": {
      "19": {
        "mean": 177.9,
        "sd": 106.9
      },
      "20": {
        "mean": 196.8,
        "sd": 88.0
      },
      "25": {
        "mean": 220.5,
        "sd": 64.3
      },
      "30": {
        "mean": 229.8,
        "sd": 55.0
      },
      "35": {
        "mean": 245.1,
        "sd": 39.7
      },
      "40": {
        "mean": 245.4,
        "sd": 39.4
      },
      "45": {
        "mean": 251.3,
        "sd": 33.5
      },
      "50": {
        "mean": 252.6,
        "sd": 32.2
      },
      "55": {
        "mean": 237.9,
        "sd": 46.9
      },
      "60": {
        "mean": 195.7,
        "sd": 89.1
      },
      "65": {
        "mean": 166.9,
        "sd": 117.9
      }
    },
    "その他": {
      "19": {
        "mean": 185.1,
        "sd": 99.7
      },
      "20": {
        "mean": 207.8,
        "sd": 77.0
      },
      "25": {
        "mean": 239.4,
        "sd": 45.4
      },
      "30": {
        "mean": 269.4,
        "sd": 15.4
      },
      "35": {
        "mean": 305.3,
        "sd": 20.5
      },
      "40": {
        "mean": 325.5,
        "sd": 40.7
      },
      "45": {
        "mean": 345.4,
        "sd": 60.6
      },
      "50": {
        "mean": 366.2,
        "sd": 81.4
      },
      "55": {
        "mean": 377.5,
        "sd": 92.7
      },
      "60": {
        "mean": 278.4,
        "sd": 6.4
      },
      "65": {
        "mean": 221.4,
        "sd": 63.4
      }
    }
  },
  "エネルギー・水道": {
    "男性": {
      "19": {
        "mean": 191.0,
        "sd": 93.8
      }
    }
  },
  "IT・通信": {
    "男性": {
      "19": {
        "mean": 201.0,
        "sd": 83.8
      },
      "20": {
        "mean": 241.7,
        "sd": 43.1
      },
      "25": {
        "mean": 293.1,
        "sd": 8.3
      },
      "30": {
        "mean": 345.6,
        "sd": 60.8
      },
      "35": {
        "mean": 403.3,
        "sd": 118.5
      },
      "40": {
        "mean": 441.4,
        "sd": 156.6
      },
      "45": {
        "mean": 487.2,
        "sd": 202.4
      },
      "50": {
        "mean": 493.4,
        "sd": 208.6
      },
      "55": {
        "mean": 519.9,
        "sd": 235.1
      },
      "60": {
        "mean": 382.5,
        "sd": 97.7
      },
      "65": {
        "mean": 286.3,
        "sd": 1.5
      }
    },
    "女性": {
      "19": {
        "mean": 186.0,
        "sd": 98.8
      },
      "20": {
        "mean": 245.0,
        "sd": 39.8
      },
      "25": {
        "mean": 268.5,
        "sd": 16.3
      },
      "30": {
        "mean": 301.9,
        "sd": 17.1
      },
      "35": {
        "mean": 350.3,
        "sd": 65.5
      },
      "40": {
        "mean": 366.1,
        "sd": 81.3
      },
      "45": {
        "mean": 365.5,
        "sd": 80.7
      },
      "50": {
        "mean": 382.1,
        "sd": 97.3
      },
      "55": {
        "mean": 385.2,
        "sd": 100.4
      },
      "60": {
        "mean": 311.3,
        "sd": 26.5
      },
      "65": {
        "mean": 235.4,
        "sd": 49.4
      }
    },
    "その他": {
      "19": {
        "mean": 191.4,
        "sd": 93.4
      },
      "20": {
        "mean": 243.1,
        "sd": 41.7
      },
      "25": {
        "mean": 283.5,
        "sd": 1.3
      },
      "30": {
        "mean": 329.4,
        "sd": 44.6
      },
      "35": {
        "mean": 387.1,
        "sd": 102.3
      },
      "40": {
        "mean": 424.6,
        "sd": 139.8
      },
      "45": {
        "mean": 459.2,
        "sd": 174.4
      },
      "50": {
        "mean": 467.5,
        "sd": 182.7
      },
      "55": {
        "mean": 495.2,
        "sd": 210.4
      },
      "60": {
        "mean": 373.9,
        "sd": 89.1
      },
      "65": {
        "mean": 276.9,
        "sd": 7.9
      }
    }
  },
  "運輸・郵便": {
    "男性": {
      "19": {
        "mean": 200.5,
        "sd": 84.3
      },
      "20": {
        "mean": 229.1,
        "sd": 55.7
      },
      "25": {
        "mean": 253.8,
        "sd": 31.0
      },
      "30": {
        "mean": 281.8,
        "sd": 3.0
      },
      "35": {
        "mean": 302.1,
        "sd": 17.3
      },
      "40": {
        "mean": 326.2,
        "sd": 41.4
      },
      "45": {
        "mean": 332.7,
        "sd": 47.9
      },
      "50": {
        "mean": 330.8,
        "sd": 46.0
      },
      "55": {
        "mean": 329.2,
        "sd": 44.4
      },
      "60": {
        "mean": 280.7,
        "sd": 4.1
      },
      "65": {
        "mean": 240.1,
        "sd": 44.7
      }
    },
    "女性": {
      "19": {
        "mean": 193.9,
        "sd": 90.9
      },
      "20": {
        "mean": 215.4,
        "sd": 69.4
      },
      "25": {
        "mean": 234.5,
        "sd": 50.3
      },
      "30": {
        "mean": 253.1,
        "sd": 31.7
      },
      "35": {
        "mean": 255.3,
        "sd": 29.5
      },
      "40": {
        "mean": 249.2,
        "sd": 35.6
      },
      "45": {
        "mean": 251.9,
        "sd": 32.9
      },
      "50": {
        "mean": 257.7,
        "sd": 27.1
      },
      "55": {
        "mean": 248.0,
        "sd": 36.8
      },
      "60": {
        "mean": 217.3,
        "sd": 67.5
      },
      "65": {
        "mean": 212.2,
        "sd": 72.6
      }
    },
    "その他": {
      "19": {
        "mean": 198.5,
        "sd": 86.3
      },
      "20": {
        "mean": 225.0,
        "sd": 59.8
      },
      "25": {
        "mean": 248.5,
        "sd": 36.3
      },
      "30": {
        "mean": 275.7,
        "sd": 9.1
      },
      "35": {
        "mean": 295.3,
        "sd": 10.5
      },
      "40": {
        "mean": 314.9,
        "sd": 30.1
      },
      "45": {
        "mean": 320.2,
        "sd": 35.4
      },
      "50": {
        "mean": 320.0,
        "sd": 35.2
      },
      "55": {
        "mean": 319.8,
        "sd": 35.0
      },
      "60": {
        "mean": 274.6,
        "sd": 10.2
      },
      "65": {
        "mean": 238.5,
        "sd": 46.3
      }
    }
  },
  "卸売・小売": {
    "男性": {
      "19": {
        "mean": 185.1,
        "sd": 99.7
      },
      "20": {
        "mean": 243.4,
        "sd": 41.4
      },
      "25": {
        "mean": 276.1,
        "sd": 8.7
      },
      "30": {
        "mean": 302.1,
        "sd": 17.3
      },
      "35": {
        "mean": 329.7,
        "sd": 44.9
      },
      "40": {
        "mean": 374.2,
        "sd": 89.4
      },
      "45": {
        "mean": 408.1,
        "sd": 123.3
      },
      "50": {
        "mean": 425.3,
        "sd": 140.5
      },
      "55": {
        "mean": 435.7,
        "sd": 150.9
      },
      "60": {
        "mean": 326.4,
        "sd": 41.6
      },
      "65": {
        "mean": 330.6,
        "sd": 45.8
      }
    },
    "女性": {
      "19": {
        "mean": 211.5,
        "sd": 73.3
      },
      "20": {
        "mean": 220.7,
        "sd": 64.1
      },
      "25": {
        "mean": 240.8,
        "sd": 44.0
      },
      "30": {
        "mean": 257.2,
        "sd": 27.6
      },
      "35": {
        "mean": 261.0,
        "sd": 23.8
      },
      "40": {
        "mean": 272.7,
        "sd": 12.1
      },
      "45": {
        "mean": 271.5,
        "sd": 13.3
      },
      "50": {
        "mean": 276.1,
        "sd": 8.7
      },
      "55": {
        "mean": 260.2,
        "sd": 24.6
      },
      "60": {
        "mean": 209.7,
        "sd": 75.1
      },
      "65": {
        "mean": 189.0,
        "sd": 95.8
      }
    },
    "その他": {
      "19": {
        "mean": 202.5,
        "sd": 82.3
      },
      "20": {
        "mean": 232.8,
        "sd": 52.0
      },
      "25": {
        "mean": 261.4,
        "sd": 23.4
      },
      "30": {
        "mean": 284.7,
        "sd": 0.1
      },
      "35": {
        "mean": 308.9,
        "sd": 24.1
      },
      "40": {
        "mean": 339.9,
        "sd": 55.1
      },
      "45": {
        "mean": 363.6,
        "sd": 78.8
      },
      "50": {
        "mean": 377.4,
        "sd": 92.6
      },
      "55": {
        "mean": 377.5,
        "sd": 92.7
      },
      "60": {
        "mean": 289.6,
        "sd": 4.8
      },
      "65": {
        "mean": 282.4,
        "sd": 2.4
      }
    }
  },
  "金融・保険": {
    "男性": {
      "19": {
        "mean": 173.7,
        "sd": 111.1
      },
      "20": {
        "mean": 239.3,
        "sd": 45.5
      },
      "25": {
        "mean": 313.7,
        "sd": 28.9
      },
      "30": {
        "mean": 406.5,
        "sd": 121.7
      },
      "35": {
        "mean": 502.1,
        "sd": 217.3
      },
      "40": {
        "mean": 591.3,
        "sd": 306.5
      },
      "45": {
        "mean": 642.4,
        "sd": 357.6
      },
      "50": {
        "mean": 624.2,
        "sd": 339.4
      },
      "55": {
        "mean": 594.8,
        "sd": 310.0
      },
      "60": {
        "mean": 361.7,
        "sd": 76.9
      },
      "65": {
        "mean": 345.9,
        "sd": 61.1
      }
    },
    "女性": {
      "19": {
        "mean": 169.3,
        "sd": 115.5
      },
      "20": {
        "mean": 222.7,
        "sd": 62.1
      },
      "25": {
        "mean": 255.7,
        "sd": 29.1
      },
      "30": {
        "mean": 299.4,
        "sd": 14.6
      },
      "35": {
        "mean": 307.3,
        "sd": 22.5
      },
      "40": {
        "mean": 326.7,
        "sd": 41.9
      },
      "45": {
        "mean": 342.8,
        "sd": 58.0
      },
      "50": {
        "mean": 330.7,
        "sd": 45.9
      },
      "55": {
        "mean": 329.5,
        "sd": 44.7
      },
      "60": {
        "mean": 296.0,
        "sd": 11.2
      },
      "65": {
        "mean": 310.7,
        "sd": 25.9
      }
    },
    "その他": {
      "19": {
        "mean": 169.7,
        "sd": 115.1
      },
      "20": {
        "mean": 229.7,
        "sd": 55.1
      },
      "25": {
        "mean": 281.5,
        "sd": 3.3
      },
      "30": {
        "mean": 344.9,
        "sd": 60.1
      },
      "35": {
        "mean": 401.1,
        "sd": 116.3
      },
      "40": {
        "mean": 450.7,
        "sd": 165.9
      },
      "45": {
        "mean": 469.9,
        "sd": 185.1
      },
      "50": {
        "mean": 466.5,
        "sd": 181.7
      },
      "55": {
        "mean": 460.5,
        "sd": 175.7
      },
      "60": {
        "mean": 331.4,
        "sd": 46.6
      },
      "65": {
        "mean": 324.1,
        "sd": 39.3
      }
    }
  },
  "不動産・賃貸": {
    "男性": {
      "19": {
        "mean": 182.7,
        "sd": 102.1
      },
      "20": {
        "mean": 237.0,
        "sd": 47.8
      },
      "25": {
        "mean": 281.7,
        "sd": 3.1
      },
      "30": {
        "mean": 323.4,
        "sd": 38.6
      },
      "35": {
        "mean": 382.5,
        "sd": 97.7
      },
      "40": {
        "mean": 408.6,
        "sd": 123.8
      },
      "45": {
        "mean": 452.1,
        "sd": 167.3
      },
      "50": {
        "mean": 482.6,
        "sd": 197.8
      },
      "55": {
        "mean": 493.1,
        "sd": 208.3
      },
      "60": {
        "mean": 338.2,
        "sd": 53.4
      },
      "65": {
        "mean": 240.9,
        "sd": 43.9
      }
    },
    "女性": {
      "19": {
        "mean": 181.4,
        "sd": 103.4
      },
      "20": {
        "mean": 228.0,
        "sd": 56.8
      },
      "25": {
        "mean": 259.7,
        "sd": 25.1
      },
      "30": {
        "mean": 267.4,
        "sd": 17.4
      },
      "35": {
        "mean": 282.6,
        "sd": 2.2
      },
      "40": {
        "mean": 299.7,
        "sd": 14.9
      },
      "45": {
        "mean": 309.8,
        "sd": 25.0
      },
      "50": {
        "mean": 299.7,
        "sd": 14.9
      },
      "55": {
        "mean": 286.8,
        "sd": 2.0
      },
      "60": {
        "mean": 257.9,
        "sd": 26.9
      },
      "65": {
        "mean": 217.8,
        "sd": 67.0
      }
    },
    "その他": {
      "19": {
        "mean": 182.1,
        "sd": 102.7
      },
      "20": {
        "mean": 232.5,
        "sd": 52.3
      },
      "25": {
        "mean": 272.5,
        "sd": 12.3
      },
      "30": {
        "mean": 301.5,
        "sd": 16.7
      },
      "35": {
        "mean": 347.4,
        "sd": 62.6
      },
      "40": {
        "mean": 371.9,
        "sd": 87.1
      },
      "45": {
        "mean": 401.0,
        "sd": 116.2
      },
      "50": {
        "mean": 419.3,
        "sd": 134.5
      },
      "55": {
        "mean": 422.8,
        "sd": 138.0
      },
      "60": {
        "mean": 316.0,
        "sd": 31.2
      },
      "65": {
        "mean": 237.0,
        "sd": 47.8
      }
    }
  },
  "学術・技術サービス": {
    "男性": {
      "19": {
        "mean": 191.6,
        "sd": 93.2
      },
      "20": {
        "mean": 235.5,
        "sd": 49.3
      },
      "25": {
        "mean": 300.6,
        "sd": 15.8
      },
      "30": {
        "mean": 343.9,
        "sd": 59.1
      },
      "35": {
        "mean": 411.3,
        "sd": 126.5
      },
      "40": {
        "mean": 445.2,
        "sd": 160.4
      },
      "45": {
        "mean": 506.8,
        "sd": 222.0
      },
      "50": {
        "mean": 526.5,
        "sd": 241.7
      },
      "55": {
        "mean": 540.3,
        "sd": 255.5
      },
      "60": {
        "mean": 491.3,
        "sd": 206.5
      },
      "65": {
        "mean": 384.6,
        "sd": 99.8
      }
    },
    "女性": {
      "19": {
        "mean": 188.9,
        "sd": 95.9
      },
      "20": {
        "mean": 230.6,
        "sd": 54.2
      },
      "25": {
        "mean": 275.6,
        "sd": 9.2
      },
      "30": {
        "mean": 299.7,
        "sd": 14.9
      },
      "35": {
        "mean": 317.6,
        "sd": 32.8
      },
      "40": {
        "mean": 325.7,
        "sd": 40.9
      },
      "45": {
        "mean": 344.9,
        "sd": 60.1
      },
      "50": {
        "mean": 360.5,
        "sd": 75.7
      },
      "55": {
        "mean": 356.6,
        "sd": 71.8
      },
      "60": {
        "mean": 314.5,
        "sd": 29.7
      },
      "65": {
        "mean": 248.3,
        "sd": 36.5
      }
    },
    "その他": {
      "19": {
        "mean": 190.9,
        "sd": 93.9
      },
      "20": {
        "mean": 233.6,
        "sd": 51.2
      },
      "25": {
        "mean": 291.1,
        "sd": 6.3
      },
      "30": {
        "mean": 331.0,
        "sd": 46.2
      },
      "35": {
        "mean": 387.1,
        "sd": 102.3
      },
      "40": {
        "mean": 410.8,
        "sd": 126.0
      },
      "45": {
        "mean": 455.1,
        "sd": 170.3
      },
      "50": {
        "mean": 480.0,
        "sd": 195.2
      },
      "55": {
        "mean": 496.4,
        "sd": 211.6
      },
      "60": {
        "mean": 465.0,
        "sd": 180.2
      },
      "65": {
        "mean": 374.9,
        "sd": 90.1
      }
    }
  },
  "宿泊・飲食": {
    "男性": {
      "19": {
        "mean": 187.5,
        "sd": 97.3
      },
      "20": {
        "mean": 211.0,
        "sd": 73.8
      },
      "25": {
        "mean": 238.9,
        "sd": 45.9
      },
      "30": {
        "mean": 261.1,
        "sd": 23.7
      },
      "35": {
        "mean": 296.8,
        "sd": 12.0
      },
      "40": {
        "mean": 314.7,
        "sd": 29.9
      },
      "45": {
        "mean": 325.6,
        "sd": 40.8
      },
      "50": {
        "mean": 339.6,
        "sd": 54.8
      },
      "55": {
        "mean": 328.7,
        "sd": 43.9
      },
      "60": {
        "mean": 281.3,
        "sd": 3.5
      },
      "65": {
        "mean": 249.0,
        "sd": 35.8
      }
    },
    "女性": {
      "19": {
        "mean": 176.9,
        "sd": 107.9
      },
      "20": {
        "mean": 203.6,
        "sd": 81.2
      },
      "25": {
        "mean": 220.8,
        "sd": 64.0
      },
      "30": {
        "mean": 228.9,
        "sd": 55.9
      },
      "35": {
        "mean": 234.8,
        "sd": 50.0
      },
      "40": {
        "mean": 234.7,
        "sd": 50.1
      },
      "45": {
        "mean": 237.9,
        "sd": 46.9
      },
      "50": {
        "mean": 227.1,
        "sd": 57.7
      },
      "55": {
        "mean": 232.0,
        "sd": 52.8
      },
      "60": {
        "mean": 208.4,
        "sd": 76.4
      },
      "65": {
        "mean": 188.6,
        "sd": 96.2
      }
    },
    "その他": {
      "19": {
        "mean": 181.4,
        "sd": 103.4
      },
      "20": {
        "mean": 206.5,
        "sd": 78.3
      },
      "25": {
        "mean": 230.0,
        "sd": 54.8
      },
      "30": {
        "mean": 247.2,
        "sd": 37.6
      },
      "35": {
        "mean": 272.0,
        "sd": 12.8
      },
      "40": {
        "mean": 283.7,
        "sd": 1.1
      },
      "45": {
        "mean": 288.4,
        "sd": 3.6
      },
      "50": {
        "mean": 293.2,
        "sd": 8.4
      },
      "55": {
        "mean": 289.1,
        "sd": 4.3
      },
      "60": {
        "mean": 251.4,
        "sd": 33.4
      },
      "65": {
        "mean": 223.3,
        "sd": 61.5
      }
    }
  },
  "生活サービス・娯楽": {
    "男性": {
      "19": {
        "mean": 187.2,
        "sd": 97.6
      },
      "20": {
        "mean": 216.2,
        "sd": 68.6
      },
      "25": {
        "mean": 252.5,
        "sd": 32.3
      },
      "30": {
        "mean": 288.6,
        "sd": 3.8
      },
      "35": {
        "mean": 313.4,
        "sd": 28.6
      },
      "40": {
        "mean": 347.4,
        "sd": 62.6
      },
      "45": {
        "mean": 360.7,
        "sd": 75.9
      },
      "50": {
        "mean": 375.2,
        "sd": 90.4
      },
      "55": {
        "mean": 377.5,
        "sd": 92.7
      },
      "60": {
        "mean": 282.5,
        "sd": 2.3
      },
      "65": {
        "mean": 227.2,
        "sd": 57.6
      }
    },
    "女性": {
      "19": {
        "mean": 189.3,
        "sd": 95.5
      },
      "20": {
        "mean": 215.4,
        "sd": 69.4
      },
      "25": {
        "mean": 237.2,
        "sd": 47.6
      },
      "30": {
        "mean": 237.9,
        "sd": 46.9
      },
      "35": {
        "mean": 251.2,
        "sd": 33.6
      },
      "40": {
        "mean": 257.0,
        "sd": 27.8
      },
      "45": {
        "mean": 251.2,
        "sd": 33.6
      },
      "50": {
        "mean": 247.5,
        "sd": 37.3
      },
      "55": {
        "mean": 241.7,
        "sd": 43.1
      },
      "60": {
        "mean": 213.9,
        "sd": 70.9
      },
      "65": {
        "mean": 188.2,
        "sd": 96.6
      }
    },
    "その他": {
      "19": {
        "mean": 188.7,
        "sd": 96.1
      },
      "20": {
        "mean": 215.7,
        "sd": 69.1
      },
      "25": {
        "mean": 244.1,
        "sd": 40.7
      },
      "30": {
        "mean": 266.0,
        "sd": 18.8
      },
      "35": {
        "mean": 288.4,
        "sd": 3.6
      },
      "40": {
        "mean": 313.3,
        "sd": 28.5
      },
      "45": {
        "mean": 315.3,
        "sd": 30.5
      },
      "50": {
        "mean": 320.2,
        "sd": 35.4
      },
      "55": {
        "mean": 316.4,
        "sd": 31.6
      },
      "60": {
        "mean": 254.6,
        "sd": 30.2
      },
      "65": {
        "mean": 211.3,
        "sd": 73.5
      }
    }
  },
  "教育": {
    "男性": {
      "19": {
        "mean": 180.3,
        "sd": 104.5
      },
      "20": {
        "mean": 233.9,
        "sd": 50.9
      },
      "25": {
        "mean": 276.9,
        "sd": 7.9
      },
      "30": {
        "mean": 342.8,
        "sd": 58.0
      },
      "35": {
        "mean": 383.6,
        "sd": 98.8
      },
      "40": {
        "mean": 439.5,
        "sd": 154.7
      },
      "45": {
        "mean": 476.4,
        "sd": 191.6
      },
      "50": {
        "mean": 511.3,
        "sd": 226.5
      },
      "55": {
        "mean": 533.9,
        "sd": 249.1
      },
      "60": {
        "mean": 493.2,
        "sd": 208.4
      },
      "65": {
        "mean": 438.6,
        "sd": 153.8
      }
    },
    "女性": {
      "19": {
        "mean": 176.1,
        "sd": 108.7
      },
      "20": {
        "mean": 221.3,
        "sd": 63.5
      },
      "25": {
        "mean": 249.1,
        "sd": 35.7
      },
      "30": {
        "mean": 281.9,
        "sd": 2.9
      },
      "35": {
        "mean": 299.8,
        "sd": 15.0
      },
      "40": {
        "mean": 322.7,
        "sd": 37.9
      },
      "45": {
        "mean": 343.3,
        "sd": 58.5
      },
      "50": {
        "mean": 374.6,
        "sd": 89.8
      },
      "55": {
        "mean": 402.7,
        "sd": 117.9
      },
      "60": {
        "mean": 401.8,
        "sd": 117.0
      },
      "65": {
        "mean": 377.1,
        "sd": 92.3
      }
    },
    "その他": {
      "19": {
        "mean": 178.7,
        "sd": 106.1
      },
      "20": {
        "mean": 224.0,
        "sd": 60.8
      },
      "25": {
        "mean": 259.1,
        "sd": 25.7
      },
      "30": {
        "mean": 311.8,
        "sd": 27.0
      },
      "35": {
        "mean": 344.8,
        "sd": 60.0
      },
      "40": {
        "mean": 383.1,
        "sd": 98.3
      },
      "45": {
        "mean": 410.2,
        "sd": 125.4
      },
      "50": {
        "mean": 446.1,
        "sd": 161.3
      },
      "55": {
        "mean": 477.8,
        "sd": 193.0
      },
      "60": {
        "mean": 464.7,
        "sd": 179.9
      },
      "65": {
        "mean": 424.2,
        "sd": 139.4
      }
    }
  },
  "医療・福祉": {
    "男性": {
      "19": {
        "mean": 178.3,
        "sd": 106.5
      },
      "20": {
        "mean": 236.8,
        "sd": 48.0
      },
      "25": {
        "mean": 273.5,
        "sd": 11.3
      },
      "30": {
        "mean": 299.0,
        "sd": 14.2
      },
      "35": {
        "mean": 330.7,
        "sd": 45.9
      },
      "40": {
        "mean": 366.8,
        "sd": 82.0
      },
      "45": {
        "mean": 378.2,
        "sd": 93.4
      },
      "50": {
        "mean": 418.0,
        "sd": 133.2
      },
      "55": {
        "mean": 447.7,
        "sd": 162.9
      },
      "60": {
        "mean": 431.9,
        "sd": 147.1
      },
      "65": {
        "mean": 433.3,
        "sd": 148.5
      }
    },
    "女性": {
      "19": {
        "mean": 198.1,
        "sd": 86.7
      },
      "20": {
        "mean": 231.3,
        "sd": 53.5
      },
      "25": {
        "mean": 256.2,
        "sd": 28.6
      },
      "30": {
        "mean": 261.9,
        "sd": 22.9
      },
      "35": {
        "mean": 271.3,
        "sd": 13.5
      },
      "40": {
        "mean": 280.3,
        "sd": 4.5
      },
      "45": {
        "mean": 287.1,
        "sd": 2.3
      },
      "50": {
        "mean": 291.7,
        "sd": 6.9
      },
      "55": {
        "mean": 295.1,
        "sd": 10.3
      },
      "60": {
        "mean": 273.3,
        "sd": 11.5
      },
      "65": {
        "mean": 253.5,
        "sd": 31.3
      }
    },
    "その他": {
      "19": {
        "mean": 190.4,
        "sd": 94.4
      },
      "20": {
        "mean": 232.5,
        "sd": 52.3
      },
      "25": {
        "mean": 261.4,
        "sd": 23.4
      },
      "30": {
        "mean": 275.4,
        "sd": 9.4
      },
      "35": {
        "mean": 293.6,
        "sd": 8.8
      },
      "40": {
        "mean": 311.3,
        "sd": 26.5
      },
      "45": {
        "mean": 314.3,
        "sd": 29.5
      },
      "50": {
        "mean": 322.3,
        "sd": 37.5
      },
      "55": {
        "mean": 329.5,
        "sd": 44.7
      },
      "60": {
        "mean": 316.9,
        "sd": 32.1
      },
      "65": {
        "mean": 314.3,
        "sd": 29.5
      }
    }
  },
  "複合サービス": {
    "男性": {
      "19": {
        "mean": 187.1,
        "sd": 97.7
      },
      "20": {
        "mean": 206.9,
        "sd": 77.9
      },
      "25": {
        "mean": 239.5,
        "sd": 45.3
      },
      "30": {
        "mean": 261.2,
        "sd": 23.6
      },
      "35": {
        "mean": 301.8,
        "sd": 17.0
      },
      "40": {
        "mean": 328.1,
        "sd": 43.3
      },
      "45": {
        "mean": 375.0,
        "sd": 90.2
      },
      "50": {
        "mean": 402.0,
        "sd": 117.2
      },
      "55": {
        "mean": 404.6,
        "sd": 119.8
      },
      "60": {
        "mean": 262.9,
        "sd": 21.9
      },
      "65": {
        "mean": 213.6,
        "sd": 71.2
      }
    },
    "女性": {
      "19": {
        "mean": 172.3,
        "sd": 112.5
      },
      "20": {
        "mean": 199.1,
        "sd": 85.7
      },
      "25": {
        "mean": 221.8,
        "sd": 63.0
      },
      "30": {
        "mean": 243.6,
        "sd": 41.2
      },
      "35": {
        "mean": 255.6,
        "sd": 29.2
      },
      "40": {
        "mean": 268.6,
        "sd": 16.2
      },
      "45": {
        "mean": 284.1,
        "sd": 0.7
      },
      "50": {
        "mean": 277.2,
        "sd": 7.6
      },
      "55": {
        "mean": 262.9,
        "sd": 21.9
      },
      "60": {
        "mean": 210.3,
        "sd": 74.5
      },
      "65": {
        "mean": 169.1,
        "sd": 115.7
      }
    },
    "その他": {
      "19": {
        "mean": 177.5,
        "sd": 107.3
      },
      "20": {
        "mean": 202.6,
        "sd": 82.2
      },
      "25": {
        "mean": 232.5,
        "sd": 52.3
      },
      "30": {
        "mean": 255.1,
        "sd": 29.7
      },
      "35": {
        "mean": 288.0,
        "sd": 3.2
      },
      "40": {
        "mean": 311.0,
        "sd": 26.2
      },
      "45": {
        "mean": 348.1,
        "sd": 63.3
      },
      "50": {
        "mean": 362.3,
        "sd": 77.5
      },
      "55": {
        "mean": 356.6,
        "sd": 71.8
      },
      "60": {
        "mean": 249.2,
        "sd": 35.6
      },
      "65": {
        "mean": 202.0,
        "sd": 82.8
      }
    }
  },
  "その他サービス": {
    "男性": {
      "19": {
        "mean": 197.9,
        "sd": 86.9
      },
      "20": {
        "mean": 225.5,
        "sd": 59.3
      },
      "25": {
        "mean": 251.6,
        "sd": 33.2
      },
      "30": {
        "mean": 284.5,
        "sd": 0.3
      },
      "35": {
        "mean": 300.5,
        "sd": 15.7
      },
      "40": {
        "mean": 329.8,
        "sd": 45.0
      },
      "45": {
        "mean": 342.2,
        "sd": 57.4
      },
      "50": {
        "mean": 361.2,
        "sd": 76.4
      },
      "55": {
        "mean": 362.0,
        "sd": 77.2
      },
      "60": {
        "mean": 278.8,
        "sd": 6.0
      },
      "65": {
        "mean": 242.2,
        "sd": 42.6
      }
    },
    "女性": {
      "19": {
        "mean": 198.4,
        "sd": 86.4
      },
      "20": {
        "mean": 215.3,
        "sd": 69.5
      },
      "25": {
        "mean": 233.7,
        "sd": 51.1
      },
      "30": {
        "mean": 240.4,
        "sd": 44.4
      },
      "35": {
        "mean": 248.8,
        "sd": 36.0
      },
      "40": {
        "mean": 255.3,
        "sd": 29.5
      },
      "45": {
        "mean": 254.0,
        "sd": 30.8
      },
      "50": {
        "mean": 264.4,
        "sd": 20.4
      },
      "55": {
        "mean": 265.2,
        "sd": 19.6
      },
      "60": {
        "mean": 234.9,
        "sd": 49.9
      },
      "65": {
        "mean": 198.5,
        "sd": 86.3
      }
    },
    "その他": {
      "19": {
        "mean": 198.1,
        "sd": 86.7
      },
      "20": {
        "mean": 221.3,
        "sd": 63.5
      },
      "25": {
        "mean": 243.8,
        "sd": 41.0
      },
      "30": {
        "mean": 267.3,
        "sd": 17.5
      },
      "35": {
        "mean": 280.7,
        "sd": 4.1
      },
      "40": {
        "mean": 303.1,
        "sd": 18.3
      },
      "45": {
        "mean": 311.1,
        "sd": 26.3
      },
      "50": {
        "mean": 327.0,
        "sd": 42.2
      },
      "55": {
        "mean": 328.5,
        "sd": 43.7
      },
      "60": {
        "mean": 266.5,
        "sd": 18.3
      },
      "65": {
        "mean": 234.0,
        "sd": 50.8
      }
    }
  },
  "電気・ガス・ 熱供給・水道業": {
    "男性": {
      "20": {
        "mean": 225.0,
        "sd": 59.8
      },
      "25": {
        "mean": 279.9,
        "sd": 4.9
      },
      "30": {
        "mean": 358.9,
        "sd": 74.1
      },
      "35": {
        "mean": 426.0,
        "sd": 141.2
      },
      "40": {
        "mean": 461.9,
        "sd": 177.1
      },
      "45": {
        "mean": 493.8,
        "sd": 209.0
      },
      "50": {
        "mean": 542.8,
        "sd": 258.0
      },
      "55": {
        "mean": 535.7,
        "sd": 250.9
      },
      "60": {
        "mean": 276.2,
        "sd": 8.6
      },
      "65": {
        "mean": 284.9,
        "sd": 0.1
      }
    },
    "女性": {
      "19": {
        "mean": 182.6,
        "sd": 102.2
      },
      "20": {
        "mean": 226.6,
        "sd": 58.2
      },
      "25": {
        "mean": 276.0,
        "sd": 8.8
      },
      "30": {
        "mean": 328.5,
        "sd": 43.7
      },
      "35": {
        "mean": 364.5,
        "sd": 79.7
      },
      "40": {
        "mean": 347.7,
        "sd": 62.9
      },
      "45": {
        "mean": 380.8,
        "sd": 96.0
      },
      "50": {
        "mean": 393.8,
        "sd": 109.0
      },
      "55": {
        "mean": 414.5,
        "sd": 129.7
      },
      "60": {
        "mean": 255.7,
        "sd": 29.1
      },
      "65": {
        "mean": 217.2,
        "sd": 67.6
      }
    },
    "その他": {
      "19": {
        "mean": 189.6,
        "sd": 95.2
      },
      "20": {
        "mean": 225.3,
        "sd": 59.5
      },
      "25": {
        "mean": 279.3,
        "sd": 5.5
      },
      "30": {
        "mean": 355.0,
        "sd": 70.2
      },
      "35": {
        "mean": 416.5,
        "sd": 131.7
      },
      "40": {
        "mean": 447.8,
        "sd": 163.0
      },
      "45": {
        "mean": 475.9,
        "sd": 191.1
      },
      "50": {
        "mean": 521.6,
        "sd": 236.8
      },
      "55": {
        "mean": 520.3,
        "sd": 235.5
      },
      "60": {
        "mean": 274.4,
        "sd": 10.4
      },
      "65": {
        "mean": 283.1,
        "sd": 1.7
      }
    }
  }
};

export default incomeData;