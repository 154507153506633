import React, { useState } from 'react';
import styled from 'styled-components';
import LabelComponent from '../../components/LabelComponent';
import CustomDropdownInput from '../../components/CustomDropdownInput'; // Ensure this is correctly imported
import GenderSelector from './GenderSelector';
import SkillSelector from './SkillSelector';

const colors = {
  background: 'rgba(255, 255, 255, 0.7)',
  text: '#102233',
};

const Container = styled.div`
  max-width: auto;
  margin: 0 auto;
  padding: 20px 20px 35px;
  text-align: center;
  background-color: ${colors.background};
  color: ${colors.text};
  border-radius: 1rem;
`;

const ageOptions = [
  "1ヶ月",
  "2ヶ月",
  "3ヶ月",
  "4ヶ月",
  "5ヶ月",
  "6ヶ月",
  "7ヶ月",
  "8ヶ月",
  "9ヶ月",
  "10ヶ月",
  "11ヶ月",
  "12ヶ月",
  "1歳～1歳半",
  "1歳半～2歳",
  "2歳半～3歳",
  "2歳半～3歳",
  "3歳半～4歳",
  ];

const PlayingStyleBase = () => {
    const [age, setAge] = useState(ageOptions[0]);
    const [gender, setGender] = useState("");
    const [skill, setSkill] = useState("");
    return (
      <Container>
        <LabelComponent text="月齢/年齢" />
        <CustomDropdownInput
          value={age}
          setValue={setAge}
          options={ageOptions}
          width="40%" //あとで調整
        />
        <LabelComponent text="性別" />
        <GenderSelector selectedGender={gender} onSelect={setGender} />
        <LabelComponent text="できること（複数選択）" />
        <SkillSelector selectedSkill={skill} onSelect={setSkill} />
      </Container>
    );
};

export default PlayingStyleBase;