import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import CustomDropdownInput from '../../components/CustomDropdownInput';
import GenderSelector from './GenderSelector'; 
import incomeData from './incomeData';
import CommentComponent from './CommentComponent';
import AdComponent from './AdComponent';
import LabelComponent from '../../components/LabelComponent';
import CheckButton from './CheckButton';

const colors = {
  background: 'rgba(255, 255, 255, 0.7)',
  text: '#333333',
};

const SimulatorContainer = styled.div`
  max-width: auto;
  margin: 0 auto;
  padding: 20px 20px 35px 20px;
  text-align: center;
  background-color: ${colors.background};
  color: ${colors.text};
  border-radius: 1rem;
`;

const Result = styled.div`
  margin-top: 1rem;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  padding: 1rem;
  border-radius: .5rem;
  background-color: #ffeb3b;
  color: #333333;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const ButtonContainer = styled.div`
display:flex; 
justify-content:flex-end; 
align-items:center; 
gap:.5rem;
`;

const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const calculateBackgroundColor = (deviation) => {
    if (deviation === null) return '#ffffff';
    const hue = Math.min(120, Math.max(0, (deviation /200 ) *120 ));
    return `hsl(${hue},70 %,70 %)`;
};

const jobOptions = [
    "選択", // Initial placeholder option
    "鉱業",
    "建設",
    "製造",
    "エネルギー・水道",
    "IT・通信",
    "運輸・郵便",
    "卸売・小売",
    "金融・保険",
    "不動産・賃貸",
    "学術・技術サービス",
    "宿泊・飲食",
    "生活サービス・娯楽",
    "教育",
    "医療・福祉",
    "複合サービス",
    "その他サービス"
];

const IncomeBase = () => {
    const [income, setIncome] = useState(100);
    const [age, setAge] = useState(18);
    const [job, setJob] = useState(jobOptions[0]);
    const [gender, setGender] = useState("");
    const [result, setResult] = useState(null);
    const resultRef = useRef(null);
    const buttonContainerRef = useRef(null);
    const calculateDeviation = (currentIncome, currentAge, currentJob, currentGender) => {
        const jobData = incomeData[currentJob];
        if (!jobData || !jobData[currentGender]) return null;

        const closestAge = Object.keys(jobData[currentGender]).reduce((a, b) => {
            return Math.abs(b - currentAge) < Math.abs(a - currentAge) ? b : a;
        });

        const { mean, sd } = jobData[currentGender][closestAge];
        return ((currentIncome - mean) / sd) * 10 + 50;
    };

    const handleCheckClick = () => {
        if (income && age && job && gender) {
            const deviation = calculateDeviation(income, age, job, gender);
            setResult(Math.round(deviation *10 ) /10 );

            // Use a timeout to ensure state is updated before scrolling
            setTimeout(() => {
                if (resultRef.current && buttonContainerRef.current) {
                    window.scrollTo({
                        top: buttonContainerRef.current.offsetTop + buttonContainerRef.current.offsetHeight,
                        behavior: 'smooth'
                    });
                }
            }, 0);
        }
    };

    const handleResetClick = () => {
        setIncome(100);
        setAge(18);
        setJob(jobOptions[0]);
        setGender(""); 
        setResult(null);
    };

return (
<SimulatorContainer>
<LabelComponent text="年齢" />
<CustomDropdownInput
    value={age}
    setValue={setAge}
    options={Array.from({ length: 83 }, (_, i) => i + 18)}
    unit="歳"
/>
<LabelComponent text="年収" />
<CustomDropdownInput
    value={income}
    setValue={setIncome}
    options={Array.from({ length: 31 }, (_, i) => i * 100)}
    unit="万円"
/>
<LabelComponent text="性別" />
<GenderSelector selectedGender={gender} onSelect={setGender} />

<LabelComponent text="職業" />
<CustomDropdownInput
      value={job}
      setValue={setJob}
      options={jobOptions}
      width="60%" // Set desired width
/>

<ButtonContainer ref={buttonContainerRef}>
  <CheckButton onClick={handleCheckClick} disabled={!job || !gender}>
    偏差値を知る
  </CheckButton>
</ButtonContainer>

{result !== null && (
<>
<CommentComponent income={income} age={age} />
<Result ref={resultRef} bgColor={calculateBackgroundColor(result)}>
あなたの年収偏差値:{result}
</Result>

<AdComponent income={income} age={age} />
</>
)}
</SimulatorContainer>
);
};

export default IncomeBase;