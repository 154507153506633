import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../components/Layout';

const BackgroundContainer = styled.div`
  background-color: #47525d; /* Set your desired background color */
  min-height: 100vh; /* Ensure it covers the full viewport height */
  font-family: 'Noto Sans', sans-serif;
`;

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: rgba(240, 244, 248, 0.7); /* Semi-transparent background */
  border-radius: 1rem;
`;

const StyledLink = styled(Link)`
  font-size: 1.8rem;
  color: #111111;
  text-decoration: none;
  margin: 15px 0;
  transition: color 0.3s;

  &:hover {
    color: #2c3e50;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 30px;
`;

const Home = () => (
  <BackgroundContainer>
    <Layout headerTitle="ONE PAGE" showSubtitle={false}>
      <HomeContainer>
        <StyledLink to="/income-sim">年収偏差値チェック</StyledLink>
        <StyledLink to="/Profile">Profile</StyledLink>
        <StyledLink to="/Disclaimer">利用規約</StyledLink>
      </HomeContainer>
    </Layout>
  </BackgroundContainer>
);

export default Home;