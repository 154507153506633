import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${props => props.subtitle ? '40px' : '30px'};
`;

const Title = styled.div`
  font-family: 'Noto Sans', sans-serif; /* Changed to Noto Sans */
  font-size: 36px;
  font-weight: 700;
  color: #f2f2f2;
  line-height: 1;
  opacity: 0.7;
`;

const Subtitle = styled.div`
  font-family: 'Noto Sans', sans-serif; /* Changed to Noto Sans */
  font-size: 10px;
  font-weight: 400;
  color: #f2f2f2;
  margin-top: 2px;
  line-height: 1;
  opacity: 0.7;
`;

const Logo = ({ title, subtitle }) => (
  <LogoContainer subtitle={!!subtitle}>
    <Title>{title}</Title>
    {subtitle && <Subtitle>{subtitle}</Subtitle>}
  </LogoContainer>
);

export default Logo;