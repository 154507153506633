import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import HomeButton from '../components/components_Button/HomeButton';

const DisclaimerContainer = styled.div`
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  color: #2c3e50;
  font-size: 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const Content = styled.div`
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.6;
  color: #34495e;
  font-size: 1rem;
`;

const Section = styled.div`
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  color: #3498db;
  font-size: 1.2rem;
  margin-bottom: 10px;
`;

const Disclaimer = () => {
  return (
    <Layout headerTitle="ONE PAGE" showSubtitle={false}>
      <DisclaimerContainer>
        <Title>利用規約</Title>
        <Content>
          <Section>
            <SectionTitle>1. 利用規約の適用</SectionTitle>
            <Paragraph>
              本利用規約は、当サイトが提供するすべてのサービスに適用されます。
              サービスを利用することで、本規約に同意したものとみなします。
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>2. サービスの内容</SectionTitle>
            <Paragraph>
              当サイトは、年収偏差値シミュレーションを提供しています。
              このシミュレーションは一般的な状況下での概算値を示すものであり、
              結果の正確性や完全性を保証するものではありません。
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>3. 利用者の責任</SectionTitle>
            <Paragraph>
              シミュレーション結果の解釈や使用は、利用者自身の責任で行ってください。
              重要な決定を行う際は、適切な専門家にご相談ください。
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>4. 知的財産権</SectionTitle>
            <Paragraph>
              当サイトのコンテンツ、ロゴ、デザイン等の知的財産権は当サイトに帰属します。
              無断での複製、転載、改変等は禁止します。
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>5. 免責事項</SectionTitle>
            <Paragraph>
              当サイトは、シミュレーション結果に基づく判断や行動によって生じたいかなる損害に対しても
              責任を負いません。また、サービスの中断、遅延、中止、データの消失等について一切の責任を負いません。
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>6. 規約の変更</SectionTitle>
            <Paragraph>
              当サイトは、必要に応じて本規約を変更することがあります。
              変更後の利用規約は、当サイトに掲載した時点で効力を生じるものとします。
            </Paragraph>
          </Section>
          <Section>
            <SectionTitle>7. 準拠法と管轄裁判所</SectionTitle>
            <Paragraph>
              本規約の解釈にあたっては、日本法を準拠法とします。
              本サービスに関して紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
            </Paragraph>
          </Section>
        </Content>
      </DisclaimerContainer>
      <HomeButton onClick={() => window.location.href = '/'} />
    </Layout>
  );
};

export default Disclaimer;