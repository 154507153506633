// src/routes.js
import Home from './pages/Home';
import IncomeSim from './pages/IncomeSim';
import Disclaimer from './pages/Disclaimer'; 
import Profile from './pages/Profile'; 
import NotFound from './pages/NotFound'; 
import PlayingStyle from './pages/PlayingStyle';

export const routes = [
  { path: '/', element: <Home />, exact: true },
  { path: '/income-sim', element: <IncomeSim /> },
  { path: '/playing-style', element: <PlayingStyle /> },
  { path: '/Profile', element: <Profile /> },
  { path: '/Disclaimer', element: <Disclaimer /> },
  { path: '*', element: <NotFound /> } // 404ページのルート
];